/* Change this file to get your personal Porfolio */

// Website related settings
const settings = {
  isSplash: false, // Change this to false if you don't want Splash screen.
};

//SEO Related settings
const seo = {
  title: "Amitesh's Portfolio",
  description:
    "I am a passionate individual dedicated to working on end-to-end products that build sustainable and scalable social and technical systems to make a meaningful impact.",
  og: {
    title: "Amitesh Verma Portfolio",
    type: "website",
  },
};

//Home Page
const greeting = {
  title: "Amitesh Verma",
  logo_name: "AmiteshVerma",
  subTitle:
    "I am a passionate individual dedicated to working on end-to-end products that build sustainable and scalable social and technical systems to make a meaningful impact.",
  resumeLink:
    "https://drive.google.com/file/d/1_VrsWL5mZ_D21E6NfxR395TQA5Ea_3xB/view?usp=sharing",

  githubProfile: "https://github.com/amitesh238",
};

const socialMediaLinks = [
  /* Your Social Media Link */
  // github: "https://github.com/ashutosh1919",
  // linkedin: "https://www.linkedin.com/in/ashutosh-hathidara-88710b138/",
  // gmail: "ashutoshhathidara98@gmail.com",
  // gitlab: "https://gitlab.com/ashutoshhathidara98",
  // facebook: "https://www.facebook.com/laymanbrother.19/",
  // twitter: "https://twitter.com/ashutosh_1919",
  // instagram: "https://www.instagram.com/layman_brother/"

  {
    name: "Github",
    link: "https://github.com/amitesh238",
    fontAwesomeIcon: "fa-github", // Reference https://fontawesome.com/icons/github?style=brands
    backgroundColor: "#181717", // Reference https://simpleicons.org/?q=github
  },
  {
    name: "LinkedIn",
    link: "https://linkedin.com/in/amitesh-verma-7b57361aa",
    fontAwesomeIcon: "fa-linkedin-in", // Reference https://fontawesome.com/icons/linkedin-in?style=brands
    backgroundColor: "#0077B5", // Reference https://simpleicons.org/?q=linkedin
  },
  {
    name: "Gmail",
    link: "mailto:vermaamitesh23@gmail.com",
    fontAwesomeIcon: "fa-google", // Reference https://fontawesome.com/icons/google?style=brands
    backgroundColor: "#D14836", // Reference https://simpleicons.org/?q=gmail
  },
  {
    name: "X-Twitter",
    link: "https://x.com/Amitesh80563974?t=YfYBRZs2geVxVtsD3GBciA&s=09",
    fontAwesomeIcon: "fa-x-twitter", // Reference https://fontawesome.com/icons/x-twitter?f=brands&s=solid
    backgroundColor: "#000000", // Reference https://simpleicons.org/?q=x
  },
  {
    name: "Facebook",
    link: "https://www.facebook.com/share/86JwrpTtkbzvGUjB/?mibextid=qi2Omg",
    fontAwesomeIcon: "fa-facebook-f", // Reference https://fontawesome.com/icons/facebook-f?style=brands
    backgroundColor: "#1877F2", // Reference https://simpleicons.org/?q=facebook
  },
  {
    name: "Instagram",
    link: "https://www.facebook.com/share/86JwrpTtkbzvGUjB/?mibextid=qi2Omg",
    fontAwesomeIcon: "fa-instagram", // Reference https://fontawesome.com/icons/instagram?style=brands
    backgroundColor: "#E4405F", // Reference https://simpleicons.org/?q=instagram
  },
];

const skills = {
  data: [
    {
      title: "Full Stack Development",
      fileName: "FullStackImg",
      skills: [
        "⚡ Building responsive website front end using React-Redux",
        "⚡ Developing mobile applications using Flutter, React Native and solo android apps using Kotlin",
        "⚡ Creating application backend in Node, Express & Flask",
      ],
      softwareSkills: [
        {
          skillName: "HTML5",
          fontAwesomeClassname: "simple-icons:html5",
          style: {
            color: "#E34F26",
          },
        },
        {
          skillName: "CSS3",
          fontAwesomeClassname: "fa-css3",
          style: {
            color: "#1572B6",
          },
        },
        {
          skillName: "Sass",
          fontAwesomeClassname: "simple-icons:sass",
          style: {
            color: "#CC6699",
          },
        },
        {
          skillName: "JavaScript",
          fontAwesomeClassname: "simple-icons:javascript",
          style: {
            backgroundColor: "#000000",
            color: "#F7DF1E",
          },
        },
        {
          skillName: "ReactJS",
          fontAwesomeClassname: "simple-icons:react",
          style: {
            color: "#61DAFB",
          },
        },
        {
          skillName: "NodeJS",
          fontAwesomeClassname: "devicon-plain:nodejs-wordmark",
          style: {
            color: "#339933",
          },
        },
        {
          skillName: "NPM",
          fontAwesomeClassname: "simple-icons:npm",
          style: {
            color: "#CB3837",
          },
        },
      ],
    },
    {
      title: "Data Science & AI",
      fileName: "DataScienceImg",
      skills: [
        "⚡ Developing highly scalable production ready models for various deeplearning and statistical use cases",
        "⚡ Experience of working with Computer Vision and NLP projects",
        "⚡ Complex quantitative modelling for dynamic forecasting and time series analysis",
      ],
      softwareSkills: [
        {
          skillName: "Tensorflow",
          fontAwesomeClassname: "logos-tensorflow",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "Keras",
          fontAwesomeClassname: "simple-icons:keras",
          style: {
            backgroundColor: "white",
            color: "#D00000",
          },
        },
        {
          skillName: "PyTorch",
          fontAwesomeClassname: "logos-pytorch",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "Python",
          fontAwesomeClassname: "ion-logo-python",
          style: {
            backgroundColor: "transparent",
            color: "#3776AB",
          },
        },
      ],
    },
  ],
};

// Education Page
const competitiveSites = {
  competitiveSites: [
    {
      siteName: "LeetCode",
      iconifyClassname: "simple-icons:leetcode",
      style: {
        color: "#F79F1B",
      },
      profileLink: "https://leetcode.com/u/vermaamitesh23/",
    },
    {
      siteName: "HackerRank",
      iconifyClassname: "simple-icons:hackerrank",
      style: {
        color: "#2EC866",
      },
      profileLink: "https://hackerrank.com/profile/vermaamitesh23",
    },
  ],
};

const degrees = {
  degrees: [
    {
      title: "G.H Raisoni College of Engineering",
      subtitle: "B.Tech. in Artificial intelligence",
      logo_path: "raisoni.png",
      alt_name: "GHRCE Nagpur",
      duration: "2021 - Present",
      descriptions: [
        "⚡ I have studied basic software engineering subjects like DS, Algorithms, DBMS, OS, CA, AI etc.",
        "⚡ Apart from this, I have done courses on  Full Stack Development and Data Structures and Algorithm.",
        "⚡ My coursework included practical projects that utilized machine learning techniques to solve real-world problems.",
      ],
      website_link: "https://ghrce.raisoni.net/",
    },
    {
      title: "Maharashtra Vidyalaya Junior College",
      subtitle: "Higher Secondry Education",
      logo_path: "12th.png",
      alt_name: "Maharashtra Vidyalaya Junior College",
      duration: "2019 - 2021",
      descriptions: [
        "⚡ I pursued my 12th-grade education in the science stream, focusing on subjects such as Physics, Chemistry, and Mathematics.",
        "⚡ I actively participated in science fairs and competitions, gaining hands-on experience in various scientific experiments and projects.",
        "⚡ My passion for science was further fueled by engaging in extracurricular activities like science clubs and attending workshops on cutting-edge technologies.",
      ],
      website_link: "https://www.indiana.edu/",
    },
  ],
};

const certifications = {
  certifications: [],
};

// Experience Page
const experience = {
  title: "Experience",

  description:
    "I have experience as a remote Front End Developer based in Nagpur, India, spanning multiple periods. I specialized in building responsive interfaces with React.js, collaborating closely with designers to convert wireframes into high-quality code. I ensured cross-browser compatibility and optimized web performance while adhering to best practices in front-end development, emphasizing clean and maintainable code.",
  sections: [
    {
      title: "Work",
      work: true,
      experiences: [
        {
          title: "Frontend Developer",
          company: "Codestream.tech.",
          company_url: "https://codestream.tech/",
          logo_path: "codestream.png",
          duration: "Aug 2023 - Dec 2023",
          location: "Nagpur, Maharashtra",
          description:
            "Codestream is a leading technology company providing innovative web development, app development, and hosting services. Our team of experts delivers cutting-edge digital solutions that will take your business to the next level.",
          color: "#fc1f20",
        },
      ],
    },
    {
      title: "Internships",
      experiences: [
        {
          title: "Frontend Developer",
          company: "Codestream.tech.",
          company_url: "https://codestream.tech/",
          logo_path: "codestream.png",
          duration: "Aug 2023 - Dec 2023",
          location: "Nagpur, Maharashtra",
          description:
            "As a Front End Developer Intern, I handled comprehensive front-end development tasks, specializing in creating responsive and user-friendly interfaces with React.js. I worked closely with design teams to translate wireframes into polished, production-ready code. My responsibilities included optimizing web performance, ensuring cross-browser compatibility, and implementing best practices for clean and maintainable front-end development.",
          color: "#fc1f20",
        },
      ],
    },
  ],
};

// Projects Page
const projectsHeader = {
  title: "Projects",
  description:
    "I have developed several dynamic web applications showcasing my proficiency in frontend development, backend integration, and deployment. These projects emphasize user-centric design, efficient data handling, and seamless functionality across various browsers and devices",
  avatar_image_path: "projects_image.svg",
};

const publicationsHeader = {
  title: "Publications",
  description: "Some of my published Articles, Blogs and Research.",
  avatar_image_path: "projects_image.svg",
};

const publications = {
  data: [],
};
const contactPageData = {
  contactSection: {
    title: "Contact Me",
    profile_image_path: "prof1.jpg",
    description:
      "I am available on almost every social media. You can message me, I will reply within 24 hours. ",
  },
  blogSection: {
    title: "Blogs",
    subtitle:
      "I like to document some of my experiences in professional career journey as well as some technical knowledge sharing.",
    link: "https://blogs.ashutoshhathidara.com/",
    avatar_image_path: "blogs_image.svg",
  },
  addressSection: {
    title: "Address",
    subtitle: "Dahegoan Rangari sai nagar Nagpur",
    locality: "Sai Nagar",
    country: "India",
    region: "Maharashtra",
    postalCode: "441113",
    streetAddress: "Nagpur",
    avatar_image_path: "address_image.svg",
    location_map_link: "https://maps.app.goo.gl/sy7uk1nX5rJNYVpS9?g_st=aw",
  },
  phoneSection: {
    title: "",
    subtitle: "",
  },
};

export {
  settings,
  seo,
  greeting,
  socialMediaLinks,
  skills,
  competitiveSites,
  degrees,
  certifications,
  experience,
  projectsHeader,
  publicationsHeader,
  publications,
  contactPageData,
};
